import React, {useState, useEffect} from 'react'
import MUIDataTable from "mui-datatables";
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Container, Paper } from "@material-ui/core/";
import { GET_CASE_REG_COMPLIANCE, VIEW_CASES, GET_CASES, SAVE_NOTE } from "../Queries";
import { Spinner } from 'reactstrap';
import axios from "axios";
import { ConstructionOutlined } from '@mui/icons-material';
import pako from 'pako';
import {Buffer} from 'buffer'
import { debounce } from 'lodash';

const ComplianceReport = () => {
    const {loading, error, data} = useQuery(GET_CASE_REG_COMPLIANCE);
    const [saveNote] = useMutation(SAVE_NOTE);
    const [note, setNote] = useState({});
    const [decompressedData, setDecompressedData] = useState([]);

    // Handle data decompression when data changes
    useEffect(() => {
        if (data?.getCaseRegCompliance?.[0]?.compressedData) {
            try {
                const compressedData = data.getCaseRegCompliance[0].compressedData;
                console.log('Compressed data length:', compressedData.length);
                
                const buffer = Buffer.from(compressedData, 'base64');
                console.log('Buffer length:', buffer.length);
                
                const decompressedBuffer = Buffer.from(pako.inflate(buffer));
                console.log('Decompressed buffer length:', decompressedBuffer.length);
                
                const decompressedString = decompressedBuffer.toString('utf8');
                console.log('Decompressed string length:', decompressedString.length);
                
                const jsonData = JSON.parse(decompressedString);
                
                const initialNotes = {};
                jsonData.forEach(row => {
                    if (row.note) {
                        console.log(`Note for case ${row.caseID}:`, {
                            value: row.note,
                            length: row.note.length,
                            type: typeof row.note,
                            charCodes: Array.from(row.note).map(c => c.charCodeAt(0))
                        });
                    }
                    initialNotes[row.caseID] = row.note || "";
                });
                
                setDecompressedData(jsonData);
                setNote(initialNotes);
            } catch (error) {
                console.error('Error processing data:', error);
            }
        } else {
            console.log('No compressed data available:', data);
        }
    }, [data]);

    const handleNoteChange = async (value, caseID) => {
        console.log('Saving note:', {
            value,
            length: value.length,
            caseID
        });

        // Immediately update local state
        setNote(prevNote => ({
            ...prevNote,
            [caseID]: value
        }));
        
        try {
            // Save to database immediately
            const result = await saveNote({ 
                variables: { 
                    caseID, 
                    note: value 
                }
            });
            console.log('Save result:', result);
        } catch (error) {
            console.error("Error saving note:", error);
        }
    };

    if (loading) return <Spinner color="dark" />;
    if (error) return <React.Fragment>Error :(</React.Fragment>;

    const extractPctsEmails = (contactInformation) => 
        contactInformation ? contactInformation.filter(e => e.emailAddrNm != null).map(e =>e.emailAddrNm).join(", ") : '';

    const columns = [
      {
        name: "caseID",
        label: "Case ID",
        options: {
         filter: false,
         sort: true,
         display: "excluded",
        }
       },
        {
         name: "caseNbr",
         label: "Case Number",
         options: {
          filter: false,
          sort: true,
         }
        },
        {
            name: "primaryAddress",
            label: "Address",
            options: {
            filter: false,
             sort: true,
            }
           },
           {
            name: "contactInformation",
            label: "PCTS Emails",
            options: {
             display: true,
             filter: false,
             sort: false,
             customBodyRender: (value, tableMeta, updateValue) => extractPctsEmails(tableMeta.rowData[3])
            }
           },
        {
         name: "filingDt",
         label: "Date of Application",
         options: {
          filter: false,
          sort: true,
         }
        },
        {
           name: "name",
           label: "Applicant, Owners, Reps",
           options: {
            display: false,
            filter: false,
           }
          },
          {
           name: "completionDt",
           label: "Date Completed",
           options: {
            filter: false,
            sort: true,
           }
          },
          {
           name: "determination",
           label: "Determination",
           options: {
            filter: false,
            sort: true,
           }
          },
          {
           name: "restrictionEndDt",
           label: "Restriction Ends",
           options: {
            filter: false,
            sort: true,
           }
          },
          {
           name: "dateRegistered",
           label: "Date Registered",
           options: {
            filter: false,
            sort: true,
           }
          },
          {
           name: "regStatus",
           label: "Registration Status",
           options: {
            filter: true,
            sort: true,
           }
          },
          {
            name: "regUserFname",
            label: "First Name",
            options: {
             display: false,
             filter: false,
            }
           },
           {
            name: "regUserLname",
            label: "Last Name",
            options: {
             display: false,
             filter: false,
            }
           },
           {
            name: "User",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                  let fname = tableMeta.rowData[11] ? tableMeta.rowData[11]: ''
                  let lname = tableMeta.rowData[12] ? tableMeta.rowData[12]: ''
                  let name = fname + ' ' + lname

                  return name.trim()
                }
                }
           },
          {
           name: "regUserEmail",
           label: "Email",
           options: {
            filter: false,
            sort: true,
           }
          },
          {
            name: "yearFiled",
            label: "Year",
            options: {
             display: false,
             filter: true,
             filterType: 'textField',
             sort: true,
            }
           },
           {
            name: "monthFiled",
            label: "Month",
            options: {
             display: false,
             filter: true,
             sort: true,
            }
           },
           {
            name: "restrictionActiveFlg",
            // change this
            label: "Restriction Active",
            options: {
             display: false,
             filter: true,
             sort: true
            }
           },
           {
            name: "ethicsRegistrationEmailed",
            label: "Planning Email Notification",
            options: {
             display: true,
             filter: false,
             sort: true,
             filterType: 'date',
             customBodyRender: (value) => value ? value : "N/A"
            }
           },
           {
            name: "ethicsRegistrationCompleted",
            label: "Planning Marked Complete",
            options: {
             display: true,
             filter: false,
             sort: true,
             filterType: 'date'
            }
           },
           {
            name: "note",
            label: "Notes",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    const caseID = tableMeta.rowData[0];
                    const currentValue = note[caseID] ?? value ?? "";
                    
                    return (
                        <textarea
                            value={currentValue}
                            onChange={(e) => handleNoteChange(e.target.value, caseID)}
                            onInput={(e) => {
                                e.target.style.height = "auto";
                                e.target.style.height = e.target.scrollHeight + "px";
                            }}
                            rows={1}
                            style={{
                                borderRadius: "6px",
                                border: "1px solid #ccc",
                            }}
                        />
                    );
                }
            }
        },
       ];
       
       
       const options = {
         filterType: 'dropdown',
         rowsPerPage: 10,
         rowsPerPageOptions: [10, 20, 50, 100],
         selectableRows: "none",
         downloadOptions: {
          filename: 'RDFSCompliance.csv', 
          filterOptions: 
            {
              useDisplayedColumnsOnly: true, 
              useDisplayedRowsOnly: true
            }
          }
       };

  return (
    
      
    <MUIDataTable 
        title={"Compliance Report"}
        data={decompressedData}
        columns={columns}
        options={options}
      
    >
    </MUIDataTable>
      
    
    
    
  )
}

export default ComplianceReport